.kubini-account-page{
    position: relative;
    width: 100%;
    padding: 2em 1.5em 1.5em;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
  }
  .kubini-account-banner{
    position: relative;
    width: 100%;
  }
  .kubini-account-avatar{
    position: relative;
    width: 100%;
    max-width: 15em;
    min-height: 20em;
    height: 100%;
    background: var(--background_tint);
    overflow: hidden;
    border-radius: var(--border_md);
    input{
      display: none;
    }
  }
  label.kubini-account-avatar-form-image{
    cursor: pointer;
  }
  .kubini-account-avatar-form-image{
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 20em;
    @extend %flex-center;
    overflow: hidden;
    img{
      min-height: 20em;
      @extend %image-center;
    }
    i{
      font-size: 3em;
    }
  }
  .kubini-account-password{
    position: relative;
    width: 100%;
    max-width: 20em;
    height: 100%;
    min-height: 20em;
    background: var(--background_tint);
    border-radius: var(--border_md);
    padding: 1em;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    transition: .3s ease-in-out;
    &.is--full{
      max-width: 100%;
    }
    &:hover{
      background: var(--primary_rgba);
      i{
        color: var(--primary);
      }
    }
    &__icon{
      width: 100%;
      text-align: var(--text-align);
      i{
        font-size: 3em;
      }
    }
    &__text{
      position: relative;
      width: 100%;
      text-align: var(--text-align);
      p{
        color: var(--color_tint);
        font-size: 1em;
      }
      strong{
        font-size: 2em;
      }
    }
  }
  .kubini-account-logout{
    position: relative;
    width: 100%;
    max-width: 20em;
    height: 100%;
    min-height: 20em;
    background: rgba($color: $danger, $alpha: .2);
    border-radius: var(--border_md);
    padding: 1em;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    transition: .3s ease-in-out;
  
    &__icon{
      width: 100%;
      text-align: var(--text-align);
      i{
        font-size: 3em;
      }
    }
    &__text{
      position: relative;
      width: 100%;
      text-align: var(--text-align);
      p{
        color: var(--color_tint);
        font-size: 1em;
      }
      strong{
        font-size: 2em;
      }
    }
  }
  .kubini-account-infos-card{
    position: relative;
    width: 100%;
    background: var(--background_tint);
    border-radius: var(--border_md);
    padding: 1em;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
    &__icon{
      width: 100%;
      text-align: var(--text-align);
      i{
        font-size: 3em;
      }
    }
  }
  button.kubini-account-information{
    transition: .3s ease;
    &:hover{
      background-color: var(--primary_rgba);
    }
  }
  .kubini-account-information{
    position: relative;
    width: calc(100% - 2em - 15em - 20em);
    height: 100%;
    min-height: 20em;
    background: var(--background_tint);
    border-radius: var(--border_md);
    padding: 1em;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    &.type-0{
      width: calc(100% - 1em - 15em);
    }
    &.type-2{
      width: calc(100% - 3em - 15em - 20em - 20em);
    }
    &.type-3{
      width: calc((100% -  17em) / 2);
    }
    &.type-4{
      width: calc((100% -  15em - 1em) / 2);
    }
    &__icon{
      width: 100%;
      text-align: var(--text-align);
      i{
        font-size: 3em;
      }
    }
  }
  .kubini-account-information-text{
    position: relative;
    width: 100%;
    &__item{
      position: relative;
      width: 100%;
      font-size: 1em;
    }
  }
  .kubini-account-content{
    position: relative;
    width: 100%;
  }
  .kubini-account-informations,
  .kubini-account-activities,
  .kubini-account-sessions{
    position: relative;
    //width: 100%;
    width: calc(100% / 2 - .8em);
    padding: 1em;
    background: var(--background_tint);
    border-radius: var(--border_md);
    min-height: 40em;
    height: 78svh;
    display: flex;
    flex-direction: column;
    row-gap: .3em;
    &.is--full{
      width: 100%;
    }
    &__list{
      position: relative;
      width: 100%;
      height: 100%;
      overflow-y: auto;
    }
  }
  
  .kubini-account-infos{
    position: relative;
    //width: 100%;
    width: calc(100% / 2 - 0.4em);
    min-height: 40em;
    height: 78svh;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
  }
  .kubini-activity{
    position: relative;
    width: 100%;
    display: flex;
    padding: 1em;
    background: var(--background);
    gap: .5em;
    &__container{
        position: relative;
        display: flex;
        flex-direction: column;
        span{
            font-size: .9em;
            opacity: 0.8;
        }
    }
  }

  .kubini-account-bg{
    position: relative;
    width: 100%;
    height: 35em;
    background: var(--background_tint);
    border-radius: var(--border_md);
    overflow: hidden;
    img{
      @extend %image-center;
    }
  }


  .enka-home{
    position: relative;
    width: 100%;
    max-width: 85em;
    padding: 10em 1em;
    padding-bottom: 3em;
    margin: auto;
  }

  .enka-home-banner{
    position: relative;
    width: 100%;
    max-width: 85em;
    height: 30em;
    margin: auto;
    margin-bottom: 1em;
    border-radius: var(--border_md);
    &::after{
      @include pseudo-el;
      width: 100%;
      height: 100%;
      border-radius: var(--border_md);
      background: var(--primary);
      top: 0;
      left: 0;
      transform: translate(-.4em, .4em);
      // z-index: 5;
    }
    &__image{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: var(--border_md);
      img{
        @extend %image-center;
      }
    }
    &__container{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      border-radius: var(--border_md);
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, var(--primary_rgba2), var(--primary_rgba), rgba(0, 0,0,.2), rgba(0, 0,0, 1));
      padding: 1em 3em;
      padding-bottom: 3em;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      row-gap: 1em;
      color: #fff;
      
      p{
        max-width: 30em;
        font-size: 1em;
      }
      h2{
        font-size: 2em;
        text-transform: uppercase;
      }
    }
  }

  .enka-home-slide-section{
    position: relative;
    width: 100%;
    max-width: 85em;
    margin: auto;
    margin-top: 2em;
    &__header{
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 1em;
      flex-wrap: wrap;
      gap: .4em;
    }
  }

  .enka-home-grid-section{
    position: relative;
    width: 100%;
    max-width: 85em;
    margin: auto;
    margin-top: 2em;
    &__header{
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 1em;
      flex-wrap: wrap;
      gap: .4em;
    }
  }
  .enka-home-grid-grid{
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1em;

  }

  .enka-ads{
    position: relative;
    width: 100%;
    margin: 1em auto;
    overflow: hidden;
    border-radius: var(--border_md);
    a{
      position: relative;
      width: 100%;
      height: 100%;
    }
    img{
      @extend %image-center;
    }
    span{
      position: absolute;
      padding: .4em .8em;
      background: var(--color_tint);
      color: var(--background);
      border-radius: var(--border_sm);
      bottom: 1em;
      right: 1em;
    }
    &.is--top{
      height: 10em;
      margin-top: 0;
    }
    &.is--side{
      height: 20em;
      margin-top: 0;
    }
  }
  .enka-home-account{
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

  }
  .enka-home-account-setting{
    position: relative;
    width: 100%;
    max-width: 20em;
    height: 80svh;
    @include media('<=920px'){
      max-width: 100%;
      height: auto;
      margin-bottom: 7em;
      h2{
        display: none;
      }
    }
    &::after{
      @include pseudo-el;
      width: 100%;
      height: 100%;
      border-radius: var(--border_md);
      background: var(--primary);
      top: 0;
      left: 0;
      transform: translate(-.4em, .4em);
      // z-index: 5;
    }

    &__container{
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 2;
      padding: 1em;
      background: var(--background_tint);
      border-radius: var(--border_md);
      display: flex;
      flex-direction: column;
      row-gap: .8em;
      height: 80svh;
      overflow-y: auto;
      @include media('<=920px'){
        width: 100%;
        height: auto;
        overflow-x: auto;
        overflow-y: visible;
        flex-direction: row;
        gap: .8em;
      }
    }
    &__item{
      position: relative;
      width: 100%;
      @include media('<=920px'){
        width: auto;
      }
      a{
        position: relative;
        width: 100%;
        min-height: 2.75em;
        display: flex;
        align-items: center;
        gap: .8em;
        background: var(--background);
        padding: 1em;
        border-radius: var(--border_sm);
        transition: .3s ease;
        @include media('<=920px'){
          width: auto;
          span{
            white-space: nowrap;
          }
        }
        &:hover{
          background: var(--primary_rgba);
        }
        &.active{
          &::after{
            @include  pseudo-el;
            top: 0;
            right: 0;
            width: 0.4em;
            height: 100%;
            background: var(--primary);
          }
        }
      }
    }
  }
  .enka-home-account-forms{
    position: relative;
    width: 100%;
    height: 80svh;
    overflow-y: auto;
    display: flex;
    // justify-content: center;
    flex-direction: column;
    row-gap: 1.3em;
    @include media('<=920px'){
      height: auto;
    }
  }
  .enka-home-account-user{
    position: relative;
    width: 100%;
    max-width: 20em;
    height: 30em;

    @include media('<=720px'){
      max-width: 100%;
    }
    &::after{
      @include pseudo-el;
      width: 100%;
      height: 100%;
      border-radius: var(--border_md);
      background: var(--primary);
      top: 0;
      left: 0;
      transform: translate(-.4em, .4em);
      // z-index: 5;
    }
    &__container{
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 2;
      padding: 1em;
      background: var(--background_tint);
      border-radius: var(--border_md);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 1em;
      height: 30em;
    }
    &__avatar{
      position: relative;
      width: 6em;
      height: 6em;
      background: var(--background);
      border-radius: var(--border_ultra_xl);
      overflow: hidden;
      font-size: 1.3em;
      @extend %flex-center;
      img{
        @extend %image-center;
      }
    }
    &__info{
      @extend %flex-center;
      flex-direction: column;
      text-align: center;
      position: relative;
      width: 100%;
    }
    &__actions{
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: .5em;
    }
    &__action{
      position: relative;
      width: 100%;
      button, a{
        position: relative;
        width: 100%;
        @extend %flex-center;
      }
    }
  }

  .enka-home-create{
    position: relative;
    margin-top: 1em;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    
    &__side{
      width: 100%;
      max-width: 20em;
      @include media('<=820px'){
        max-width: 100%;
      }
    }
    &__container{
      position: relative;
      width: 100%;
      min-width: 36em;
      max-width: calc(100% - 21em);
      display: flex;
      flex-direction: column;
      row-gap: 2em;
      // padding: 1em;
      @include media('<=820px'){
        max-width: 100%;
        min-width: 100%;
      }
    }
    &__fields{
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: .8em;
    }
  }

  .enka-home-account-container{
    position: relative;
    width: 100%;
    min-width: 36em;
    max-width: calc(100% - 20em);
    padding: 1em;
    
    @include media('<=720px'){
      max-width: 100%;
      min-width: 100%;
    }
  }
  .enka-home-account-navigation{
    position: relative;
    max-width: 100%;
    padding-bottom: .8em;
    border-bottom: .1em solid var(--color_rgba);
    width: 100%;
    overflow-x: auto;
    
    &__item{
        position: relative;
        padding: 0 .5em;
        white-space: nowrap;
        font-size: 1.2em;
        &.is--active{
            font-weight: bold;
            color: var(--primary);
            &::after{
                @include pseudo-el;
                width: 100%;
                height: 0.2em;
                background: var(--primary);
                bottom: -.6em;
                left: 0;
            }
        }
    }
    &__content{
      position: relative;
      width: auto;
      display: flex;
      gap: .8em;
    }
    &__container{
      position: relative;
      width: 100%;
      display: none;
      padding: 1em 0;
      &.is--active{
        display: block;
      }
    }
  }
  .enka-home-account-grid{
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: .8em;
  }
  .enka-home-account-grid-item{
    position: relative;
    width: 100%;
    max-width: 18em;
    @include media('<=720px'){
      max-width: calc(50% - .8em);
    }
    @include media('<=320px'){
      max-width: 100%;
    }
    &.type2{
      max-width: 25em;
      @include media('<=720px'){
        max-width: calc(50% - .8em);
      }
      @include media('<=320px'){
        max-width: 100%;
      }
    }
  }
  .enka-home-account-action{
    position: relative;
    width: 100%;
    @extend %flex-center;
    margin-top: 1em;
  }

 
  .enka-home-slide-item{
    position: relative;
    width: 100%;
    max-width: 18em;
    padding-right: .8em;
    &.type2{
      max-width: 25em;
      padding-right: .8em;
    }

   
  }

  .enka-home-grid-item{
    position: relative;
    width: 100%;
    max-width: 18em;
    // padding-right: .8em;
    &.type2{
      max-width: 25em;
      @include media('<=720px'){
        max-width: calc(50% - .8em);
      }
      @include media('<=320px'){
        max-width: 100%;
      }
    }
    @include media('<=720px'){
      max-width: calc(50% - .8em);
    }
    @include media('<=320px'){
      max-width: 100%;
    }
  }

.enka-arrow{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 3em;
    height: 3em;
    background: var(--color);
    color: var(--background);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: var(--border_ultra_xl);
    z-index: 2;
    &#next{
        right: -10px;
        i{
          transform: scaleX(-1);
        }
    }
    &.slick-disabled{
      display: none;
    }
    &#prev{
        left: -10px;
        
    }
}


.enka-setting-avatar{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1em;
  &__container{
      position: relative;
      width: 10em;
      height: 10em;
      input{
          display: none;
      }
  }
  &__actions{
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;
      gap: .4em;
      flex-wrap: wrap;
      button, label{
          @extend %flex-center;
          height: 2.5em;
      }
  }
}
.enka-setting-avatar-image{
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  @extend %flex-center;
  border-radius: 50%;
  cursor: pointer;
  i{
      font-size: 2em;
  }
  img{
      @extend %image-center;
  }
  background: var(--background_tint);

}
.enka-home-account-form{
  position: relative;
  width: 100%;
  padding: 1em;
  background: var(--background_tint);
  border-radius: var(--border_md);
  display: flex;
  flex-direction: column;
  row-gap: 2em;
  &__container{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1.5em;
  }
  &__fields{
    position: relative;
    width: 100%;
    display: flex;
    // flex-direction: column;
    gap: .5em;
    row-gap: 1em;
    flex-wrap: wrap;
  }
  &__field{
    position: relative;
    width: calc(50% - 1em);
    @include media('<=620px'){
      width: 100%;
      
    }
  }
  &__action{
    position: relative;
    width: 100%;
    button{
      width: 100%;
      @extend %flex-center;
    }
  }
}

.enka-home-account-list{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  &__header{
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: .5em;
    align-items: center;
    flex-wrap: wrap;
  }
  &__container{
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: .8em;
  }
}


.enka-product-banner{
  position: relative;
  width: 100%;
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
  &__side{
    position: relative;
    width: 100%;
    max-width: 25em;
    height: 45em;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
    @include media('<=1020px'){
      max-width: 100%;
    }
  }
  &__ads{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: var(--border_md);
    background: var(--background);
    overflow: hidden;
    display: flex;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    span{
        position: absolute;
        bottom: 1em;
        left: 1em;
        background: var(--background);
        color: var(--color);
        padding: .5em 1em;
        border-radius: 1em;
        font-size: .8em;
    }
  }
}
.enka-product-banner-info{
  border-radius: var(--border_md);
  background: var(--background_tint);
  border: .02em solid var(--color_rgba);
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 2em;
  padding: 1em;
  &__details{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .7em;
    h2{
      font-size: 2.1em;
    }
    a{
      text-transform: uppercase;
      font-weight: bold;
      text-decoration: underline;
      &:hover{
        color: var(--primary);
      }
    }
  }
  &__orders{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .7em;
  }
  &__price{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .2em;
    strong{
      font-size: 2.4em;
    }
    span{
      font-size: .8em;
      opacity: .9;
    }
  }
  &__qte{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1em;
    flex-wrap: wrap;
    button{
      position: relative;
      width: 2.5em;
      height: 2.5em;
      background: var(--background_tint);
      border-radius: 50%;
      border: .04em solid var(--color_rgba);
      @extend %flex-center;
      transition: .4s ease;
      &.is--disable{
        pointer-events: none;
        opacity: .4;
      }
    }
    input{
      max-width: 4em;
      text-align: center;
      color: inherit;
      background: none;
      outline: none;
      border: none;
    }
  }
  &__actions{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .4em;
    margin-top: 2em;
    button{
      justify-content: center;
      width: 100%;
      min-height: 3em;
    }
  }
  &__share{
    position: absolute;
    top: .5em;
    right: .5em;
    opacity: .8;
    &:hover{
      opacity: 1;
    }
    font-size: 1.2em;
  }
}
.enka-product-banner-info-avis{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1em;
  flex-wrap: wrap;
  span{
    text-decoration: underline;
    opacity: .7;
  }
  &__stars{
    position: relative;
    display: flex;
    align-items: center;
    gap: .5em;
  }
  &__star{
    position: relative;
    width: 1.2em;
    height: 1.2em;
    background: var(--color);
    clip-path: polygon(50% 0%, 63% 31%, 98% 35%, 73% 58%, 79% 91%, 50% 74%, 21% 91%, 27% 57%, 2% 35%, 36% 32%);
    opacity: .5;
    &.is--active{
      opacity: 1;
    }
  }
}
.enka-product-banner-images{
  position: relative;
  width: 100%;
  max-width: 57em;
  height: 45em;
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  @include media('<=1020px'){
    max-width: 100%;
  }
}
.enka-product-banner-image-main{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: var(--background_tint);
  border-radius: var(--border_md);
  img{
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.enka-product-banner-description{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: var(--background_tint);
  border-radius: var(--border_md);
  padding: 1em;
  strong{
    padding-bottom: .8em;
    border-bottom: .1em solid var(--color_rgba);
    margin-bottom: 1em;
    display: block;
    width: 100%;
  }
}
.enka-product-banner-image-list{
  position: relative;
  width: 100%;
}
.enka-product-banner-image-lil{
  position: relative;
  padding-right: .5em;
  button{
    position: relative;
    overflow: hidden;
    width: 6.5em;
    height: 6.5em;
    padding: .4em;
    border-radius: var(--border_md);
    border: .2em solid transparent;
    &.is--active{
      border-color: var(--primary);
    }
    img{
      @extend %image-center;
      border-radius: var(--border_md);
    }
  }
}

.enka-product-navigation{
  position: relative;
  width: 100%;
  padding: .8em;
  border-radius: var(--border_md);
  background: var(--background_tint);
  margin: 1em 0;

  &__container{
    position: relative;
    width: 100%;
    display: none;
    padding: 1em 0;
    min-height: 40em;
    height: 80svh;
    overflow-y: auto;
    &.is--active{
      display: block;
    }
  }
}
.enka-product-navigation-nav{
  position: relative;
  width: 100%;
  padding-bottom: .8em;
  border-bottom: .1em solid var(--color_rgba);
  display: flex;
  overflow-x: auto;
  gap: .8em;
  &__item{
      position: relative;
      padding: 0 .5em;
      white-space: nowrap;
      font-size: 1em;
      &.is--active{
          font-weight: bold;
          color: var(--primary);
          &::after{
              @include pseudo-el;
              width: 100%;
              height: 0.2em;
              background: var(--primary);
              bottom: -.8em;
              left: 0;
          }
      }
  }
}
.enka-product-banner-info-user{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: .8em;
  flex-wrap: wrap;
  margin-top: .8em 0;
  &__avatar{
    width: 3.5em;
    height: 3.5em;
    background-color: var(--background);
    border-radius: var(--border_ultra_xl);
    overflow: hidden;
    @extend %flex-center;
    img{
      @extend %image-center;
    }
  }
  &__info{
    display: flex;
    flex-direction: column;
  }
}

.enka-product-form{
  position: relative;
  width: 100%;
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
}
.enka-product-form-likes{
  &__btn{
    position: relative;
    width: auto;
    padding: .9em 1em;
    border: .04em solid var(--color_rgba);
    display: flex;
    gap: .8em;
    align-items: center;
    background: var(--background);
    border-radius: var(--border_md);
    font-size: 1em;
    &.is--disable{
      pointer-events: none;
      opacity: 0.4;
    }
    &.is--active{
      background: var(--primary_rgba);
      border-color: var(--primary);
    }
  }
}
.enka-product-form-comments{
  position: relative;
  width: 100%;
  max-width: 42em;
  display: flex;
  gap: .5em;
  &__field{
    position: relative;
    width: 100%;
    textarea{
      position: relative;
      width: 100%;
      padding: .9em 1em;
      border: .04em solid var(--color_rgba);
      min-height: 3.2em;
      height: 3.2em;
      background: var(--background_tint);
      border-radius: var(--border_md);
      font-size: 1em;
      color: inherit;
      resize: vertical;
      max-height: 7em;
    }
  }
  &__btn{
    position: relative;
    width: auto;
    padding: .9em 1em;
    display: flex;
    gap: .8em;
    align-items: center;
    background: var(--primary_rgba);
    border-radius: var(--border_md);
    font-size: 1em;
    &.is--disable{
      pointer-events: none;
      opacity: 0.4;
    }
  }
}
.enka-product-comments{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: .8em;
  margin-top: 2em;
}

.enka-product-comment{
  position: relative;
  width: 100%;
  display: flex;
  padding: .8em;
  border-radius: var(--border_md);
  border: .04em solid var(--color_rgba);
  gap: 1em;
  &__user{
    width: 3em;
    height: 3em;
    border-radius: var(--border_ultra_xl);
    overflow: hidden;
    position: relative;
    background: var(--background);
    img{
      @extend %image-center;
    }
    @extend %flex-center;
    i{
      font-size: 1em;
    }
  }
  &__container{
    position: relative;
    width: calc(100% - 4em);
    display: flex;
    flex-direction: column;
    padding-bottom: 2em;
    
  }
  span{
    font-size: .8em;
    position: absolute;
    bottom: 0.8em;
    left: 0;
    opacity: .8;
  }
  button{
    position: absolute;
    bottom: 0.8em;
    right: 0;
    opacity: .8;
    text-decoration: underline;
    display: flex;
  }
}


.enka-home-products-more{
  position: relative;
  width: 100%;
  display: flex;
  gap: 1em;
  justify-content: center;
  opacity: .6;
  align-items: center;
  font-size: .9em;
  margin: 2em 0;
  span{
    white-space: nowrap;
  }

}

.enka-home-products-more-line{
  position: relative;
  width: 100%;
  height: 0.06em;
  background: currentColor;
}

.enka-modal-share{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1em;
  padding: 2em;
  &__header{
    position: relative;
    width: 100%;
    text-align: center;
    h2{
      font-family: var(--font_title);
      font-size: 1.8em;
    }
  }
}
.enka-modal-share-copy{
  position: relative;
  width: 100%;
  height: 3em;
  display: flex;
  column-gap: .3em;
  &__container{
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: .8em;
    padding: .8em 1em;
    display: flex;
    align-items: center;
    font-weight: 500;
    border: .1em solid var(--color_rgba);
  }
  &__btn{
    position: relative;
    width: 3em;
    height: 3em;
    background: var(--background_tint);
    @extend %flex-center;
    border-radius: .8em;
    &.is--active{
      background: var(--primary_rgba);
    }
  }
}
.enka-modal-share-socials{
  position: relative;
  width: 100%;
  margin-bottom: 2em;
  @extend %flex-center;
  gap: .8em;
}
.enka-modal-share-social{
  position: relative;
    width: 3em;
    height: 3em;
    background: var(--background);
    @extend %flex-center;
    border-radius: 50%;
    transition: .3s ease;
    svg{
      width: 1.3em;
      height: 1.3em;
      path{
        fill: currentColor;
      }
    }
    &.facebook{
      &:hover{
        background: rgb(62, 0, 196);
        color: #fff;
      }
    }
    &.instagram{
      &:hover{
        background: linear-gradient(75deg, rgb(111, 0, 255), rgb(255, 106, 0));
        color: #fff;
      }
    }
    &.twitter{
      &:hover{
        background: rgb(1, 7, 9);
        color: #fff;
      }
    }
    &.linkedin{
      &:hover{
        background: rgb(1, 96, 184);
        color: #fff;
      }
    }
    &.email{
      &:hover{
        background: rgb(184, 1, 99);
        color: #fff;
      }
    }
    &.phone{
      &:hover{
        background: rgb(0, 150, 102);
        color: #fff;
      }
    }
    &.whatsapp{
      &:hover{
        background: rgb(1, 142, 67);
        color: #fff;
      }
    }
    &.telegram{
      &:hover{
        background: rgb(0, 69, 153);
        color: #fff;
      }
    }
}

.enka-search-page{
  position: relative;
  width: 100%;
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
}
.enka-search-side{
  position: relative;
  width: 100%;
  max-width: 20em;
  display: flex;
  flex-direction: column;
  row-gap: .8em;

  @include media('<=700px'){
    max-width: 100%;
    max-height: 30em;
    overflow-y: auto;
  }
  &__search{
    position: relative;
    width: 100%;
    min-height: 2.75em;
    padding: .5em;
    background: var(--background_tint);
    border-radius: var(--border_xl);
    display: flex;
    align-items: center;
    column-gap: .4em;
    input{
      width: 100%;
      height: 100%;
      background: none;
      border: none;
      color: inherit;
    }
  }
}

.enka-search-side-range{
  position: relative;
  width: 100%;
  padding: 1em;
  background: var(--background_tint);
  border-radius: var(--border_md);
  display: flex;
  flex-direction: column;
  row-gap: .8em;
  padding-bottom: 2em;
  &__container{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .4em;
  }
  &__prices{
    position: relative;
    width: 100%;
    display: flex;
    gap: .5em;
    margin-bottom: 1.3em;
    justify-content: space-between;
    flex-wrap: wrap;
    span{
      position: relative;
      padding: .5em 1em;
      background: var(--background);
      border-radius: var(--border_xl);
      @extend %flex-center;
    }
  }
  &__price{
    position: relative;
    padding: .5em 1em;
    background: var(--background);
    border-radius: var(--border_xl);
    display: flex;
    gap: .2em;
    input{
      width: 5em;
      border: none;
      background: none;
      color: inherit;
      font-size: .8em;
    }
  }
}
.enka-search-range{
  position: relative;
  width: calc(100% - 1em);
  margin-bottom: 1em;
  margin: auto;
  height: 0.3em;
  background: var(--background);
  border-radius: var(--border_xl);
  .thumb{
    width: 1.5em;
    height: 1.5em;
    border-radius: var(--border_ultra_xl);
    background: var(--primary);
    cursor: pointer;
    transform: translateY(-40%);
  }
  .track-1{
    background-color: var(--primary);
    top: 0;
    bottom: 0;
  }
}
.enka-search-side-categories{
  position: relative;
  width: 100%;
  max-height: 45em;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  &__list{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .8em;
  }
  &__subcategories{
    position: relative;
    padding-left: 1em;
    display: flex;
    flex-direction: column;
    row-gap: .5em;
  }
}
.enka-search-side-categorie{
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: .4em;
  &__box{
    position: relative;
    width: 1.3em;
    height: 1.3em;
    border: .1em solid var(--color_tint);
    border-radius: var(--border_sm);
    &::after{
      @include pseudo-el;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
      height: 50%;
      background: var(--primary);
      border-radius: var(--border_sm);
      opacity: 0;
      transition: .3s ease;
    }
    &.is--active{
      &::after{
        opacity: 1;
      }
    }
  }
}

.enka-search-container{
  position: relative;
  width: 100%;
  max-width: calc(100% - 21em);
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  @include media('<=700px'){
    max-width: 100%;
  }
}

.enka-home-rules{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1em;
}

.enka-home-rule{
  position: relative;
  width: 100%;
  padding: 1.5em;
  background: var(--background_tint);
  border-radius: .8em;
  display: flex;
  flex-direction: column;
  gap: 1em;

}

.enka-partners{
  position: relative;
  width: 100%;
  margin-top: 2em;
  &__header{
    position: relative;
    width: 100%;
    margin-bottom: 1em;
    text-align: center;
  }
  &__container{
    position: relative;
    width: 100%;
  }
}

.enka-partner-slide-item{
  position: relative;
  width: 100%;
  max-width: 8em;
  height: 9em;
  padding: .5em;
}
.enka-partner{
  position: relative;
  width: 7em;
  height: 100%;
  padding: .5em;
  background: var(--background_tint);
  @extend %flex-center;
  border-radius: .5em;
  img{
    width: 100%;
    height: auto;
  }
  span{
    position: absolute;
    white-space: nowrap;
    padding: .2em;
    background:  var(--color);
    color: var(--background);
    border-radius: .2em;
    transform: translateY(3.5em);
    z-index: 2;
    display: none;
  }
  &:hover{
    span{
      display: block;
    }
  }
}